import { render, staticRenderFns } from "./SecaoAvaliar.vue?vue&type=template&id=2d4c2f28&"
import script from "./SecaoAvaliar.js?vue&type=script&lang=js&"
export * from "./SecaoAvaliar.js?vue&type=script&lang=js&"
import style0 from "./SecaoAvaliar.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn});
