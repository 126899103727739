import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'SecaoAvaliar',
  components: { VueSlickCarousel },
  props: {
    title: {
      type: String,
      default: 'Você tem um carro?'
    }
  },
  data: () => ({
    condicoes: [
      {
        img: 'icones/layout/calendar-2',
        nome: 'Carro com até 10 anos ou 120 mil km rodados'
      },
      {
        img: 'icones/layout/eye',
        nome: 'Você mesmo pode realizar a vistoria on-line'
      },
      {
        img: 'icones/layout/wrench',
        nome: 'Carro sem restrições'
      }
    ],
    carrosselCards: {
      // lazyLoad: 'ondemand',
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      rows: 1,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: false,
      responsive: [
        {
          breakpoint: 2400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 599,
          settings: {
            rows: 3,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  })
}
